@import '../utils/base';

.overview__card.overview__card {
  text-align: center;
  border-top: 3px solid var(--brand-color);
  color: inherit;
  text-decoration: none;
}

.overview__card-title {
  text-transform: uppercase;
  color: $textPlaceholder;
}
